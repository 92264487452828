import styled from "styled-components";
import { theme } from "../../theme";

export const NavBarContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.secondary};
  padding: 15px;
  text-align: center;
  position: relative;

  span {
    color: ${theme.colors.white};
    font-weight: bold;
    opacity: 0.8;
    padding-right: 25px;
    padding-left: 25px;
    position: relative;
    &:after {
      content: '';
      width: 3px;
      height: 21px;
      background: linear-gradient(90deg, rgba(249,171,1,1) 13%, rgba(190,37,35,1) 86%);
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  a {
    &:hover {
      text-decoration: none;
      span {
        opacity: 1;
      }
    }
  }

  a:last-child {
    span {
      &:after {
      content: none;
      }
    }
  }

  .navbar-column-mob {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 50px;

    nav {
      width: 100%;
    }

    a {
      display: block;
      width: 100%;
      padding: 20px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, rgba(249,171,1,1) 13%, rgba(190,37,35,1) 86%);
      }
    }

    span {
      color: ${theme.colors.black};

      &:after {
        content: none;
      }
    }
  }
`

export const NavBarBurgerCta = styled.button`
  width: 45px;
  height: 45px;
  position: absolute;
  right: 20px;
  bottom: 10px;
  border-radius: 8px;
  background: linear-gradient(to top, rgba(249,171,1,1) 13%, rgba(190,37,35,1) 86%);
  border: 2px solid white;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 2px;
    background: white;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 2px;
    background: ${theme.colors.white};
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const NavbarBurgerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: ${theme.colors.white};
`

export const BurgerClose = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 28px;
  border: none;
  background: none;
  z-index: 9999;
`