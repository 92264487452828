import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BurgerClose, NavBarBurgerCta, NavBarContainer, NavbarBurgerContainer } from './styles'
import { useState } from 'react'

const NavBar = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const pages = [
    {
      path: '/',
      label: 'Home'
    },
    {
      path: '/obbiettivi/',
      label: 'Obbiettivi'
    },
    {
      path: '/servizi/',
      label: 'Servizi'
    },
    {
      path: '/media/',
      label: 'Media'
    },
    {
      path: '/contatti/',
      label: 'Contatti'
    },
  ]

  const checkActiveState = (path) => {
    const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
    if (currentPath.replaceAll('/', '') === path.replaceAll('/', '')) {
      return true;
    }
  }

  let isDesktop = true;

  if (typeof window !== "undefined") {
    isDesktop = window.innerWidth > 768;
  }

  return(
  <>
    {isDesktop ? (
      <NavBarContainer>
        <Container>
          <Row>
            <Col xs={12} className="navbar-column">
              <nav>
                {pages.map((page, index) => (
                  <Link key={index} to={page.path}><span style={{ opacity: checkActiveState(page.path) && '1' }}>{page.label}</span></Link>
                ))}
              </nav>
            </Col>
          </Row>
        </Container>
      </NavBarContainer>
    ) : (
      <NavBarContainer>
        <NavBarBurgerCta onClick={() => setBurgerOpen(true)}>

        </NavBarBurgerCta>
        {burgerOpen && (
          <NavbarBurgerContainer>
            <BurgerClose onClick={() => setBurgerOpen(false)}>X</BurgerClose>
            <Container>
              <Row>
                <Col xs={12} className="navbar-column-mob">
                  <nav>
                    {pages.map((page, index) => (
                      <Link key={index} to={page.path}><span style={{ opacity: checkActiveState(page.path) && '1' }}>{page.label}</span></Link>
                    ))}
                  </nav>
                </Col>
              </Row>
            </Container>
          </NavbarBurgerContainer>
        )}
      </NavBarContainer>
    )}
    
  </>
  )
}

export default NavBar