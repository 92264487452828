import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { HeaderContainer } from './styles'
import logo from '../../images/logo-2-white.png'

const Header = () => (
  <header>
    <HeaderContainer>
      <Container>
        <Row style={{ alignItems: 'center' }}>
          <Col xs={12}>
            <div className="logo-title-container">
              <Link to="/"><img src={logo} alt="impresa raf service logo" /></Link>
              <h1>RAF SERVICE<span>IMPRESA SERVIZI</span></h1>
            </div>
          </Col>
        </Row>
      </Container>
    </HeaderContainer>
  </header>
)

export default Header