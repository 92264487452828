import styled from "styled-components";
import { theme } from "../../theme";

export const WelcomeOverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0; 
  background-color:  ${theme.colors.secondary};
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  animation: disappear 1s;
  animation-delay: 2s;

  p {
    width: 100%;
    text-align: center;
    font-family: Verdana;
    text-transform: uppercase;
  }
    
  .overlay-title {
    font-size: 80px;
    margin-bottom: 10%;
    @media only screen and (max-width: 768px) {
        font-size: 50px;
    }
  }
  .overlay-years {
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
    }

    strong {
        background: linear-gradient(90deg,rgba(249,171,1,1) 13%,rgba(190,37,35,1) 86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 90px;
        @media only screen and (max-width: 768px) {
            font-size: 50px;
        }
        
    }
    span {
        padding-left: 10px;
    }
  }
  .overlay-description {
    font-size: 60px;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
    }
  }

  /* img {
    position: absolute;
    width: 86px;
    height: 86px;
    top: 22px;
    left: 95px;
    animation: spin 2s infinite;
  } */

  @keyframes disappear { 
    100% { 
        opacity: 0;
    } 
  }
`