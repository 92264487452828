import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FooterContainer } from './styles'

const Footer = () => (
  <footer>
    <FooterContainer>
      <Container>
        <Row> 
          <Col xs={12}>
            <h6>Impresa Raf Service © {new Date().getFullYear()}</h6>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  </footer>
)

export default Footer