import styled from "styled-components";
import { theme } from "../../theme";

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  background-color: ${theme.colors.secondary};

  h6 {
    color: ${theme.colors.white};
    margin: 0;
  }
`;