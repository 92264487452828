import styled from "styled-components";
import { theme } from "../../theme";

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.secondary};
  padding: 20px 0 40px 0;
  position: relative;

  img {
    margin: 0;
    width: 72px;
    height: 82px;
    margin-right: 20px;
  }

  @media screen (max-width: 767px) {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 20px;
      background: linear-gradient(90deg, rgba(249,171,1,1) 13%, rgba(190,37,35,1) 86%);
    }
  }

  h1 {
    color: ${theme.colors.white};
    font-weight: bold;

    span {
      display: block;
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .logo-title-container {
    display: flex;
    align-items: center;
  }
`