/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./customstyle.css"
import Footer from "./Footer"
import Navbar from "./NavBar"
import WelcomeOverlay from "./WelcomeOverlay"
import { useState } from "react"
/* import Aos from "aos";
import "aos/dist/aos.css"; */

const Layout = ({ children }) => {
  const [showOverlay, setShowOveraly] = useState(false); 

  useEffect(() => {
   /*  Aos.init({
      once: true,
      duration: 800
    }); */
    if (sessionStorage && !sessionStorage.getItem('firstOverlay')) {
      setShowOveraly(true);
      sessionStorage.setItem('firstOverlay', 'true');
    }
  }, []);

  return (
    <>
      <Header />
      <Navbar />
      <main>{children}</main>
      {showOverlay && (
        <WelcomeOverlay />
      )}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
