export const theme = {
  colors: {
    primary: '#333333',
    secondary: '#008296',
    accented: '#96AF64',
    white: '#FFFFFF',
    accentedBanner: '#EEEEE5',
    black: '#000000'
  },
  spacing: {
    betweenRows: '50px'
  }
}