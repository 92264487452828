import React from 'react'
import { WelcomeOverlayContainer } from './styles'
import logo from '../../images/logo.jpg'
import { useEffect } from 'react'
import { useState } from 'react'

const WelcomeOverlay = () => {
  const [hideOverlay, setHideOverlay] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHideOverlay(true);
    }, 3000)
  }, []);
  return (
    <>
    {!hideOverlay && (
      <WelcomeOverlayContainer>
      <div>
        <p className="overlay-title"><strong>IMPRESA RAF SERVICE</strong></p>
        <p className="overlay-years"><strong>30</strong><span>ANNI</span></p>
        <p className="overlay-description">Di pulizia e manutenzione</p>
      </div>
    </WelcomeOverlayContainer>
    )}
    </>
  )
}

export default WelcomeOverlay